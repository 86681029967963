/* 
    Error flash message
*/
// Core react and plugins
import React, { Fragment } from 'react';

// Component class itself
const ValidationError = (props) => {

    if (!props.errors || typeof props.errors[props.fieldName] == "undefined" || props.errors[props.fieldName] === "") {
        return <Fragment></Fragment>
    }

    return (

        <div className="val-msg">
            <p>{ props.errors[props.fieldName] }</p>
        </div>

    );

}

export default ValidationError;
